import * as React from "react"
import { useState } from "react"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Layout from "../components/layout"

const Contact = () => {
  const [FormSubmitted, setFormSubmitted] = useState(false)
  const [formSentState, setFormSentState] = useState(false)
  const apiEndPoint = "https://smartop-back-preprod.azurewebsites.net/api/"
  const [FormState, setFormState] = useState({
    isFormValid: false,
    contactForm: {
      institutionName: {
        id: "w-node-_5bdd78f8-cdf6-7591-2977-ac458df9ef45-5eb642d1",
        label: "Nom de l'institution",
        touched: false,
        inputtype: "input",
        type: "text",
        value: "",
        isValid: false,
        validation: {
          required: true
        }
      },
      function: {
        id: "w-node-_05217a9b-a982-60b2-1fa9-9092f91ab2e5-5eb642d1",
        label: "Fonction",
        touched: false,
        inputtype: "input",
        type: "text",
        value: "",
        isValid: false,
        validation: {
          required: true
        }
      },
      lastName: {
        id: "w-node-_5bdd78f8-cdf6-7591-2977-ac458df9ef49-5eb642d1",
        label: "Nom",
        touched: false,
        inputtype: "input",
        type: "text",
        value: "",
        isValid: false,
        validation: {
          required: true
        }
      },
      firstName: {
        id: "w-node-_81b893a1-8cfe-55dd-8a6d-b3d27b895bc4-5eb642d1",
        label: "Prénom",
        touched: false,
        inputtype: "input",
        type: "text",
        value: "",
        isValid: false,
        validation: {
          required: true
        }
      },
      email: {
        id: "w-node-_5bdd78f8-cdf6-7591-2977-ac458df9ef4d-5eb642d1",
        label: "Email professionnel",
        touched: false,
        inputtype: "input",
        type: "text",
        value: "",
        isValid: false,
        validation: {
          required: true,
          regularExpression: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        }
      },
      phone: {
        id: "w-node-_5bdd78f8-cdf6-7591-2977-ac458df9ef51-5eb642d1",
        label: "Téléphone",
        type: "text",
        touched: false,
        inputtype: "input",
        value: "",
        isValid: false,
        validation: {
          required: true
        }
      },
      subject: {
        id: "w-node-_5bdd78f8-cdf6-7591-2977-pc458df9ef4d-5eb642d1",
        label: "Sujet",
        touched: false,
        inputtype: "input",
        value: "",
        isValid: false,
        validation: {
          required: true
        }
      },
      message: {
        id: "w-node-_5bdd78f8-cdf6-7591-2977-dc458df9ef4d-5eb642d1",
        label: "Message",
        touched: false,
        inputtype: "textarea",
        value: "",
        isValid: false,
        validation: {
          required: true
        }
      }
    }
  })

  const isValid = (value, rules) => {
    let valid = true
    if (rules.required) valid = valid && value.trim() !== ""
    if (rules.minLength) valid = valid && value.length >= rules.minLength
    if (rules.regularExpression)
      valid = valid && rules.regularExpression.test(value)
    return valid
  }

  const _isFormValid = form => {
    let isFormValid = true
    for (let key in form) {
      isFormValid = isFormValid && form[key].isValid
    }

    return isFormValid
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    const contactFormUpdated = { ...FormState.contactForm }
    const inputIdentifierUpdated = { ...contactFormUpdated[inputIdentifier] }
    inputIdentifierUpdated.value = event.target.value
    inputIdentifierUpdated.isValid = isValid(
      inputIdentifierUpdated.value,
      inputIdentifierUpdated.validation
    )
    inputIdentifierUpdated.touched = true
    contactFormUpdated[inputIdentifier] = inputIdentifierUpdated
    setFormState({
      contactForm: contactFormUpdated,
      isFormValid: _isFormValid(contactFormUpdated)
    })
  }

  const formSubmittedHandler = e => {
    setFormSubmitted(true)
    e.preventDefault()
    if (isFormValid) {
      const { contactForm } = FormState
      let body = {}
      for (let key in contactForm) {
        body = {
          ...body,
          [key]: {
            value: contactForm[key].value,
            label: contactForm[key].label
          }
        }
      }
      setFormSentState(true)
      setFormState({ ...FormState, isFormValid: true })
      axios
        .post(apiEndPoint + "contactForm", body)
        .then(() => {
          toast.success(
            "Merci de votre prise de contact, nous vous répondrons dans les plus brefs délais.",
            {
              position: toast.POSITION.BOTTOM_CENTER
            }
          )
        })
        .catch(err => {
          setFormSentState(false)
          toast.error("Une erreur s'est produite, réessayer encore une fois!", {
            position: toast.POSITION.BOTTOM_CENTER
          })
        })
    }
  }

  const { contactForm, isFormValid } = FormState
  let inputs = []
  for (let key in contactForm) {
    const { id, label, type, inputtype, isValid, touched } = contactForm[key]
    inputs.push(
      <div id={id}>
        <label for="Fonction">{label}*</label>
        {inputtype === "input" ? (
          <input
            type={type}
            class="input w-input"
            onChange={event => inputChangedHandler(event, key)}
          />
        ) : (
          <textarea
            type="text"
            class="text-area"
            onChange={event => inputChangedHandler(event, key)}
          />
        )}
        {!isValid && (touched || FormSubmitted) ? (
          <p className="error">Le champs est invalide</p>
        ) : null}
      </div>
    )
  }

  return (
    <Layout>
      <div id="mainContent_div" class="mainContent contact-section">
        <div class="contact-form-card">
          <h2 class="title">Nous contacter</h2>
          <p class="description">
            Besoin d'aide, d'une offre ou d'informations supplémentaires ? Notre
            équipe se fera un plaisir de vous aider.
          </p>
          <div class="w-form">
            <form onSubmit={formSubmittedHandler} id="wf-form-D-mo-Combin" name="wf-form-D-mo-Combin"
                  data-name="Démo Combiné" class="contact-form">
              {inputs}
              <a onClick={formSubmittedHandler} data-wait="Patientez..."
                 id="w-node-_5bdd78f8-cdf6-7591-2977-ac458df9ef55-5eb642d1" class="button-submit">Envoyer</a>
            </form>
          </div>
        </div>
        <div class="right-container">
          <div class="details-container">
            <div class="title">
              Téléphone
            </div>
            <div class="subtitle">
              +33 01 76 39 12 45
            </div>
            <div class="subtitle">
              +33 07 57 90 17 63
            </div>
          </div>
          <div className="details-container">
            <div className="title">
              Email
            </div>
            <div className="subtitle">
              contact@smartop.io
            </div>
          </div>
          <div className="details-container">
            <div className="title">
              Adresses
            </div>
            <div className="subtitle">
              63 rue Ordener, 75018 Paris, France
            </div>

            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.2790277561166!2d2.3485607156755175!3d48.891019279290596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e66d221389d%3A0xdab3ad12f573c506!2s63%20Rue%20Ordener%2C%2075018%20Paris%2C%20France!5e0!3m2!1sen!2sma!4v1619049143187!5m2!1sen!2sma"
                style={{ border: "0", width: "100%", height: "330px" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  )
}

export default Contact
